$(document).ready(function () {

    /* ----------------------------------------------------------- */
    /*  1. datetime picker in forms
    /* ----------------------------------------------------------- */
    if ($.datetimepicker) {
        // get language
        var userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split('-')[0];

        // datetimepicker
        if (userLang == 'hu') {
            $.datetimepicker.setLocale('hu');
        }
        else {
            $.datetimepicker.setLocale('en');
        }

        $('.js-datepicker').datetimepicker({
                dayOfWeekStart: 1,
                timepicker: true,
                step: 1,
                format: 'Y-m-d H:i'
            }
        );
    }

    /* ----------------------------------------------------------- */
    /*  2. ADMIN Left menu
    /* ----------------------------------------------------------- */
    var hamburger = document.getElementById('hamburger');

    if (hamburger) {
        hamburger.addEventListener("click", toggleSidenav);
    }

    function toggleSidenav() {
        document.body.classList.toggle('sidenav-active');
    }

    // ==============
    // Automated Demo
    /*const interval = setInterval(toggleSidenav, 2500);
    document.addEventListener('mousemove', removeInterval);
    document.addEventListener('click', removeInterval);

    function removeInterval() {
        clearInterval(interval);
        document.removeEventListener('mousemove', removeInterval);
        document.removeEventListener('click', removeInterval);
    }
    */

    /* ----------------------------------------------------------- */
    /*  3. COUNTER up home page
    /* ----------------------------------------------------------- */

    $('.counter').each(function () {
        var $this = $(this);
        $({ Counter: 0 }).animate({ Counter: $this.text() }, {
            duration: 3000,
            easing: 'swing',
            step: function () {
                $this.text(Math.ceil(this.Counter));
            }
        });
    });

});